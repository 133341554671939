<template>
	<EditDataPasienKecantikan v-if="cabang.tipe == 'kecantikan'" />
	<EditDataPasienKesehatan v-else />
</template>

<script setup>
import { computed } from 'vue'
import store from '@/store'
import EditDataPasienKecantikan from './components/EditDataPasienKecantikan.vue'
import EditDataPasienKesehatan from './components/EditDataPasienKesehatan.vue'

const cabang = computed(() => store.getters['auth/cabang'])
</script>
